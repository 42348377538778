export class CalculationRulesFilterImp {
    id = ""
    typeRule = ""
    timeCondition = null
    isActive = null
    metricIds = ""
    typeAggregation = null
    typeInterpolation = null
    defaultValue = ""
    typeWorkTime = null
    formula = ""
    containsValue = ""
    metricTypeId = null
    sortParams = [{sortFieldName: "isActive", direction: "desc"}]
}
