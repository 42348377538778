export class Util {
    public static transformToCheckboxes(values){
        values.map(val => {
            val.value = val?.id;
            val.label = val.name;
        });

        return values;
    }

    public static transformToCheckboxes2(values){
        values.map(val => {
            val.value = val.id;
            val.label = val.fio;
        });

        return values;
    }


    public static transformToCheckboxes3(values){
        values.map(val => {
            val.value = val.id;
            val.label = val.number;
        });

        return values;
    }

    public static transformToCheckboxesString(values){
        const result: any[] = [];
        values.forEach((val, ind) => {
            result.push({
                value: ind, label: val
            })
        }
    );

        return result;
    }

    public static transformToCheckboxesStringVal(values){
        const result: any[] = [];
        values.forEach((val, ind) => {
            result.push({
                value: val, label: val
            })
        }
    );

        return result;
    }

    public static transformToCheckboxesAllName(values){
        const result: any[] = [];
        values.forEach((val, ind) => {
                result.push({
                    value: val.name, label: val.name
                })
            }
        );

        return result;
    }


    public static onCheckLastValue(array, name) { // only last value
        if(array[name].length == 0) {
            array[name] = [''];
        } else {
            array[name] = [array[name][array[name].length - 1]];
        }
    }

    public static onCheckManyValues(array, name) { // many values
        if (array[name].length == 0) {
            array[name] = [''];
        } else if (array[name][array[name].length - 1] === '') {
            array[name] = [''];
        } else if (array[name].length > 0) {
            const index = array[name].indexOf('');
            if (index > -1) {
                array[name].splice(index, 1);
            }
        }
    }
}
