
import { IncidentFilterImp } from "@/components/classes/IncidentFilterImp";
import { IncidentsApi } from "@/api/incidents.api";
import { Util } from "@/components/utils/Util";
import moment from "moment";
import {mixins, Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import Super from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import { AreaApi } from "@/api/area.api";
import { ServiceApi } from "@/api/service.api";
import {currentUser} from "@/consts/currentUser";
import {CustomAttributesApi} from "@/api/custom-attributes.api";
import {attribute} from "postcss-selector-parser";
import {ExecutorUsersApi} from "@/api/executor-users.api";

export default defineComponent({
  name: "IncidentsFilter",
  components: {
    Accordion,
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler(val) {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  data: () => ({
    filter: new IncidentFilterImp(),
    currentUser,
    hasQueryClient: false,
    areas: [],
    areaName: '',
    serviceName: '',
    customerAgentName: '',
    responsibleName: '',
    creationDateEnd: null,
    creationDateStart: null,
    valuesForCheckboxes: {
      area: [],
      areasArr: [],
      responseZone: [],
      responseZoneArr: [],
      services: [],
      servicesArr: [],
      incidentSources: [],
      incidentSourcesArr: [],
      priorities: [],
      prioritiesArr: [],
      incidentStatuses: [],
      incidentStatusesArr: [],
      customerAgentArr: [],
      customerAgent: [],
      responsibleArr:[],
      responsible: [],
      supportTeams: [],
      supportTeamsArr: [],
      clients: [],
      clientsArr: [''],
      typePassedTime: [
        {value: '', label: 'Неважно'},
        {value: 'lessThanTenMinutes', label: 'Менее 10 минут'},
        {value: 'betweenTenMinutesAndOneHour', label: 'От 10 минут до 1 часа'},
        {value: 'betweenOneHourAndFourHours', label: 'От 1 часа до 4х часов'},
        {value: 'moreThanFourHours', label: 'Свыше 4х часов'},
      ],
      typePassedTimeArr: [''],
      onWorkTimeArr:[],
      archive: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'},
      ],
      deleted: ['false'],
    },
    filterData: {},
    period: [],
    customAttributesList: []
  }),
  methods: {
    customDataFilterMethod(event, attr) {
      if (event.length > 0) {
        console.log(event[0]._d)
        this.filter.customAttributes = this.filter.customAttributes.map(res => {
          if (res.name === attr.attributeName) {
            res.dateTo = moment(event[1]._d).format("DD.MM.YYYY HH:mm:ss");
            res.dateFrom = moment(event[0]._d).format("DD.MM.YYYY HH:mm:ss");
          }
          return res;
        })
      }
      else {
        this.filter.customAttributes = this.filter.customAttributes.map(res => {
          if (res.name === attr.attributeName) {
            res.dateTo = '';
            res.dateFrom = '';
          }
          return res;
        })
      }
      this.$emit("filter", this.filter);
    },
    customBooleanFilterMethod(event, attr){
      this.filter.customAttributes = this.filter.customAttributes.map(res => {
        if (res.name === attr.attributeName) {
          res.value = attr.checkboxValue[0];
        }
        return res;
      })
      this.$emit("filter", this.filter);
    },
    customFilterMethod(event, attr){
      this.$emit("filter", this.filter);
    },
    getCustomAttributeList() {
      CustomAttributesApi.getSearchableCustomAttributes('incident')
          .then((res) => {
            this.customAttributesList = res;
            this.formatCustomAttributesListToFilter(this.customAttributesList);
          })
    },
    formatCustomAttributesListToFilter(customAttributesList) {
      this.filter.customAttributes = customAttributesList.map(attribute => {
        if(attribute.type === 'text') {
          return {
            label: attribute.name,
            value: '',
            ...attribute,
            name: attribute.attributeName,
          };
        }
        if(attribute.type === 'date') {
          return {
            label: attribute.name,
            dateFrom: '',
            dateTo: '',
            ...attribute,
            gapDateValueForDatepicker: [],
            name: attribute.attributeName,
          }
        }
        if(attribute.type === 'boolean') {
          return  {
            label: attribute.name,
            value: '',
            ...attribute,
            checkboxValue: [""],
            name: attribute.attributeName,
          }
        }
      })
    },
    handleChangePeriod(ev) {
      this.creationDateEnd = ev[1];
      this.creationDateStart = ev[0];
      this.setQueryParams();
      this.applyFilter('filter')
    },
    changeFilter() {
      this.setQueryParams();
    },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    getAreas(areaName?) {
      const name = areaName || this.areaName;
      IncidentsApi.getAreas({containsValue: name})
          .then(res => {
            this.valuesForCheckboxes.areas = Util.transformToCheckboxes(res.data);
          });
    },
    getServices(serviceName?) {
      const orderN = serviceName || this.serviceName;
      ServiceApi.applyFilter({ orderN },10,0)
        .then(res => {
          this.valuesForCheckboxes.services = Util.transformToCheckboxes(res.data);
        });
    },
    getCustomerAgentList(customerAgentName?) {
      const fio = customerAgentName || this.customerAgentName;
      if (!this.canEditResponseZone()) {
        ExecutorUsersApi.applyFilter({fio: fio}, 50, 0)
            .then(res => {
              this.valuesForCheckboxes.customerAgent = Util.transformToCheckboxes(res.data)
            })
      }
    },
    getResponsibleList(responsibleName?) {
      const fio = responsibleName || this.responsibleName;
      if (!this.canEditResponseZone()) {
        ExecutorUsersApi.applyFilter({userTypeId: [2],fio: fio}, 50, 0)
            .then(res => {
              this.valuesForCheckboxes.responsible = Util.transformToCheckboxes(res.data)
            })
      }
    },
    onCheckLastValue(array, name) { // only last value
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) { // many values
      Util.onCheckManyValues(array, name);
    },
    canEditResponseZone() {
      return  this.currentUser.value.userRoles.includes('client-operator')
    },
    onCheckWorkTime() {
      if(this.valuesForCheckboxes.onWorkTimeArr.length == 0) {
        this.valuesForCheckboxes.onWorkTimeArr = ['false'];
      } else {
        this.valuesForCheckboxes.onWorkTimeArr =
            [this.valuesForCheckboxes.onWorkTimeArr[this.valuesForCheckboxes.onWorkTimeArr.length - 1]];
      }
    },
    applyFilter() {
      console.log(this.valuesForCheckboxes)
      this.filter.areaId = this.valuesForCheckboxes.areasArr[0];
      this.filter.responsible = this.valuesForCheckboxes.responsibleArr.length > 0 && this.valuesForCheckboxes.responsibleArr[0] !== '' ?
          this.valuesForCheckboxes.responsibleArr : []
      this.filter.incidentSources = (this.valuesForCheckboxes.incidentSourcesArr.length === 1
          && this.valuesForCheckboxes.incidentSourcesArr[0] === '')
          ? [] : this.valuesForCheckboxes.incidentSourcesArr;
      this.filter.priorities = (this.valuesForCheckboxes.prioritiesArr.length === 1
          && this.valuesForCheckboxes.prioritiesArr[0] === '')
          ? [] : this.valuesForCheckboxes.prioritiesArr;
      this.filter.incidentStatuses = (this.valuesForCheckboxes.incidentStatusesArr.length === 1
          && this.valuesForCheckboxes.incidentStatusesArr[0] === '')
          ? [] : this.valuesForCheckboxes.incidentStatusesArr;
      this.filter.supportTeams = (this.valuesForCheckboxes.supportTeamsArr.length === 1
          && this.valuesForCheckboxes.supportTeamsArr[0] === '')
          ? [] : this.valuesForCheckboxes.supportTeamsArr;
      this.filter.services = (this.valuesForCheckboxes.servicesArr.length === 1
          && this.valuesForCheckboxes.servicesArr[0] === '')
          ? [] : this.valuesForCheckboxes.servicesArr;
      this.filter.customerAgentIds = (this.valuesForCheckboxes.customerAgentArr.length === 1
          && this.valuesForCheckboxes.customerAgentArr[0] === '')
          ? [] : this.valuesForCheckboxes.customerAgentArr;
      this.filter.typePassedTime = this.valuesForCheckboxes.typePassedTimeArr[0] === ''
          ? null : this.valuesForCheckboxes.typePassedTimeArr[0];
      this.filter.onWorkTime = this.valuesForCheckboxes.onWorkTimeArr[0] === 'true';
      this.filter.clientId = this.valuesForCheckboxes.clientsArr[0] === '' ? [] : this.valuesForCheckboxes.clientsArr;
      this.filter.responseZoneId = (this.valuesForCheckboxes.responseZoneArr.length === 1
        && this.valuesForCheckboxes.responseZoneArr[0] === '')
        ? null : this.valuesForCheckboxes.responseZoneArr[0];
      this.filter.deleted = this.valuesForCheckboxes.deleted[0] === ''
          ? false : this.valuesForCheckboxes.deleted[0] === 'true';

      if (this.creationDateStart) {
        this.filter.creationDateStart = moment(this.creationDateStart).format("DD.MM.YYYY");
      } else {
        this.filter.creationDateStart = null;
      }
      if (this.creationDateEnd) {
        this.filter.creationDateEnd = moment(this.creationDateEnd).format("DD.MM.YYYY");
      } else {
        this.filter.creationDateEnd = null;
      }
      this.setPassedTime(this.valuesForCheckboxes.typePassedTimeArr[0]);
      this.filterChange(new IncidentFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      if (!this.$route.query.isRelated) {
        let queryParams: any = {};
        this.setQueryFromFilterParam(queryParams, ['id', 'name', 'description']);
        if (this.creationDateStart) {
          queryParams.creationDateStart = moment(this.creationDateStart).format('DD.MM.YYYY');
        }
        if (this.creationDateEnd) {
          queryParams.creationDateEnd = moment(this.creationDateEnd).format('DD.MM.YYYY');
        }
        queryParams.onWorkTime = this.valuesForCheckboxes.onWorkTimeArr[0] === 'true';
        this.setQueryParamsArrDefault(queryParams, ['onWorkTimeArr' , "areas" , "status" , "customerAgent"]);
        this.$router.push({query: queryParams});
      }
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['id', 'name', 'description']);
      this.setCheckboxesArrFromQuery(query,
          ['priorities', 'incidentSources', 'status', 'supportTeams', 'clients' , "areas" , "customerAgent"]);
      if(query.onWorkTime) {
        this.valuesForCheckboxes.onWorkTimeArr[0] = query.onWorkTime;
      }
      if(query.areas) {
        this.valuesForCheckboxes.areasArr = [+query.areas];
      }
      if(query.typePassedTime) {
        this.valuesForCheckboxes.typePassedTimeArr = [query.typePassedTime];
        this.setPassedTime(query.time);
      }
      if(query.creationDateStart) {
        this.creationDateStart = moment(query.creationDateStart, 'DD.MM.YYYY');
        this.period.push(this.creationDateStart);
      }
      if(query.creationDateEnd) {
        this.creationDateEnd = moment(query.creationDateEnd, 'DD.MM.YYYY');
        this.period.push(this.creationDateEnd);
      }
      if (query.clients) {
        this.hasQueryClient = true;
      }
    },
    setPassedTime(
        time:
            | 'lessThanTenMinutes'
            | 'betweenTenMinutesAndOneHour'
            | 'betweenOneHourAndFourHours'
            | 'moreThanFourHours',
    ) {
      if (time === 'lessThanTenMinutes') {
        this.filter.timeWorkingStart = 0;
        this.filter.timeWorkingEnd = 599;
        return;
      }
      if (time === 'betweenTenMinutesAndOneHour') {
        this.filter.timeWorkingStart =  600;
        this.filter.timeWorkingEnd = 3599;
        return;
      }
      if (time === 'betweenOneHourAndFourHours') {
        this.filter.timeWorkingStart =  3600;
        this.filter.timeWorkingEnd = 14399;
        return;
      }
      if (time === 'moreThanFourHours') {
        this.filter.timeWorkingStart = 14400;
        this.filter.timeWorkingEnd = null;
        return;
      }
    },
    cleanFilter() {
      this.period = [];
      this.filter = new IncidentFilterImp();
      this.creationDateEnd = null;
      this.creationDateStart = null;
      this.$router.push({query: {}});
      this.defaultValueFilter();
      this.setFilterFields();
      this.formatCustomAttributesListToFilter(this.customAttributesList);
      this.filterChange(new IncidentFilterImp());
      this.$emit("filter", this.filter);
    },
    setDefaultValueCheckboxes(res){
      this.valuesForCheckboxes.areas = Util.transformToCheckboxes(res.areas);
      this.valuesForCheckboxes.incidentSources = Util.transformToCheckboxes(res.sources);
      this.valuesForCheckboxes.priorities = Util.transformToCheckboxes(res.priority);
      this.valuesForCheckboxes.clients = Util.transformToCheckboxes(res.clients);
      this.valuesForCheckboxes.incidentStatuses = Util.transformToCheckboxes(res.statuses);
      this.valuesForCheckboxes.supportTeams = Util.transformToCheckboxes(res.supportTeams);
      this.valuesForCheckboxes.responseZone = Util.transformToCheckboxes(res.responseZone);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes(['clientsArr', 'incidentStatusesArr', 'onWorkTimeArr']);
      this.valuesForCheckboxes.incidentStatusesArr = this.filter.incidentStatuses;
      this.valuesForCheckboxes.onWorkTimeArr = ['false'];
      this.filter.timeWorkingStart = null;
      this.filter.timeWorkingEnd = null;
      this.valuesForCheckboxes.clientsArr = [''];;
      this.filter.clientId = this.valuesForCheckboxes.clientsArr[0] === '' ? [] : this.valuesForCheckboxes.clientsArr;
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            // To default value
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'description') {
              this.filter.description = '';
            }
            if(f.field === 'areaName') {
              this.valuesForCheckboxes.areasArr = [''];
            }
            if(f.field === 'status') {
              this.valuesForCheckboxes.incidentStatusesArr = [''];
            }
            if(f.field === 'source') {
              this.valuesForCheckboxes.incidentSourcesArr = [''];
            }
            if(f.field === 'priority') {
              this.valuesForCheckboxes.prioritiesArr = [''];
            }
            if(f.field === 'supportTeam') {
              this.valuesForCheckboxes.supportTeamsArr = [''];
            }
          });
    },
  },
  mounted() {
    IncidentsApi.getIncidentFilterList().then((res) => {
      this.getServices()
      this.getCustomerAgentList();
      this.setDefaultValueCheckboxes(res);
      this.getResponsibleList()
      this.defaultValueFilter();
      this.getQueryParams();
      this.setFilterFields();
      this.applyFilter();
    });
    this.getCustomAttributeList();
  },
  setup() {
    return {
      locale
    }
  },
})
