import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mt-4" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "p-input-icon-left large-input" }
const _hoisted_4 = { class: "mt-4 list-in-tab-block-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_IncidentHistoryList = _resolveComponent("IncidentHistoryList")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: 1100,
    visible: _ctx.visible,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.visible) = $event)),
    centered: "",
    title: "История"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          key: "back",
          class: "secondary small",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
        }, "Отмена")
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_a_input, {
            value: _ctx.history.search,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.history.search) = $event)),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSearchHistoryChanges($event))),
            type: "text",
            class: "input-base clear-icon-v",
            placeholder: "Поиск по автору, описанию, статусу",
            "allow-clear": ""
          }, null, 8, ["value"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_IncidentHistoryList, {
          fields: _ctx.history.fields,
          "onUpdate:fields": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.history.fields) = $event)),
          list: _ctx.history.list,
          size: _ctx.history.size,
          page: _ctx.history.page,
          totalCount: _ctx.history.totalCount,
          onChangePage: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updatePage($event, 'history'))),
          onChangeSorter: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateList($event, 'history'))),
          isLoad: _ctx.isLoad
        }, null, 8, ["fields", "list", "size", "page", "totalCount", "isLoad"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}