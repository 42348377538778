export class AreaFilterImp {
    id = null;
    active = null;
    activity = null;
    areaTypes = [];
    city = "";
    cityNames = [];
    containsValue = "";
    district = "";
    districtNames = [];
    division = "";
    divisions = [];
    name = "";
    region = "";
    regionCodes = [];
    regionNames = [];
    sortParams = [{sortFieldName: "id", direction: "desc"}];
    stages = [];
    street = "";
    streetNames = [];
    areaStatuses = [];
    code = null;
}
