
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";
import { IncidentsApi } from "@/api/incidents.api";
import axios from "axios";

export default defineComponent({
  mixins: [ListBase],
  mounted() {
    console.log(this.list, "eto list");
    console.log(this.filteredFields, "eto filterer");
  },
  data() {
    return {
      downloadProgress: 0,
      downloadFileId: undefined,
    };
  },
  methods: {
    async downloadFile({ file: { id, name } }) {
      const token = localStorage.getItem('auth_token');
      console.log(`Bearer ${token}`);
      this.downloadFileId = id;

      try {
        const response = await axios({
          url: `/files/file/${id}`,
          method: "get",
          responseType: "arraybuffer",
          headers: {
            "Accept": "application/octet-stream",
            "Authorization": `Bearer ${token}`
          },
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
              this.downloadProgress = percentComplete.toFixed(2);
              console.log(this.downloadProgress);
            }
          }
        });

        const blob = new Blob([response.data], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);

        this.downloadProgress = 0;
        this.downloadFileId = undefined;

      } catch (error) {
        console.error('Ошибка при скачивании файла:', error);
      }
    }
  }
});
