
import {defineComponent, PropType} from "vue";
import FilterBase from "@/classes/FilterBase.vue";
import {Field} from "@/types/fields";
import moment from "moment";
import {ChangelogFilterImp} from "@/components/classes/ChangelogFilterImp";
import {ChangelogApi} from "@/api/changelog.api";
import Accordion from "@/components/ui/Accordion.vue";
import ExecutorUsers from "@/views/executor-users/ExecutorUsers.vue";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import {Util} from "@/components/utils/Util";

export default defineComponent ({
  name: "ChangelogFilter",
  components: {Accordion},
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter();
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    handleChangeTime (ev) {
      console.log(ev)
      console.log(321)
      if (ev) {
        this.setQueryParams();
        this.applyFilter('filter')
      }
      else {
        this.value = ''
        this.filter.date = ''
        this.setQueryParams();
        this.applyFilter('filter')
      }
    },
    applyFilter() {
      this.filterChange(new ChangelogFilterImp());
      this.filter.entityClass = this.valuesForCheckboxes.entityClassArr[0] || ''
      this.filter.changeType  = this.valuesForCheckboxes.changeTypeArr[0] || ''
      this.filter.userId = (this.valuesForCheckboxes.usersArr.length === 1
          && this.valuesForCheckboxes.usersArr[0] === '')? '' : this.valuesForCheckboxes.usersArr[0];
      this.filter.changedEntityFields = (this.valuesForCheckboxes.atributArr.length === 1
          && this.valuesForCheckboxes.atributArr[0] === '')? [] : this.valuesForCheckboxes.atributArr;
      if (this.creationDateStart) {
        this.filter.commitDateTimeStart = moment(this.creationDateStart).format("DD.MM.YYYY HH:mm:ss");
      } else {
        this.filter.commitDateTimeStart = null;
      }
      if (this.creationDateEnd) {
        this.filter.commitDateTimeEnd = moment(this.creationDateEnd).format("DD.MM.YYYY HH:mm:ss");
      } else {
        this.filter.commitDateTimeEnd = null;
      }
      console.log(this.valuesForCheckboxes)
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ['calculationTypeRule']);
      this.setQueryParamsArrDefault(queryParams, []);
      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['id']);
      this.setCheckboxesArrFromQuery(query, []);

    },
    cleanFilter() {
      this.filter = new ChangelogFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new ChangelogFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    getAtributList(atributName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.atributArr.length > 0) {
        this.valuesForCheckboxes.atributArr.forEach(id => {
          const checked = this.valuesForCheckboxes.atributs.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = atributName || this.atributName;
      ChangelogApi.getAtributFieldList(name).then(res => {
        this.valuesForCheckboxes.atributs = Util.transformToCheckboxes(res);
        if(arrChecked.length > 0) {
          arrChecked.forEach(c => {
            if (!this.valuesForCheckboxes.atributs.some(mg => mg.id === c.id)) {
              this.valuesForCheckboxes.atributs.push(c);
            }
          });
        }
      })
    },
    getAuditClassesList() {
      ChangelogApi.getAuditClasses().then(res => {
        this.valuesForCheckboxes.entityClassList = res;
      })
    },
    getUserList(userName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.usersArr.length > 0) {
        this.valuesForCheckboxes.usersArr.forEach(id => {
          const checked = this.valuesForCheckboxes.users.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = userName || this.userName;
      ExecutorUsersApi.applyFilter({containsValue: name}).then(res=> {
        this.valuesForCheckboxes.users = Util.transformToCheckboxes(res.data);
        if(arrChecked.length > 0) {
          arrChecked.forEach(c => {
            if (!this.valuesForCheckboxes.users.some(mg => mg.id === c.id)) {
              this.valuesForCheckboxes.users.push(c);
            }
          });
        }
      })
    },
    handleChangePeriod(ev) {
      this.creationDateEnd = ev[1];
      this.creationDateStart = ev[0];
      this.setQueryParams();
      this.applyFilter('filter')
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
              console.log(f)
          });
    },
  },
  mounted() {
    console.log(123)
    this.defaultValueFilter();
    this.getQueryParams();
    this.setFilterFields();
    this.getAuditClassesList();
    this.getAtributList();
    this.getUserList();
    this.applyFilter();
  },
  data: () => ({
    filter: new ChangelogFilterImp(),
    filterData: {},
    value: '',
    userName: '',
    atributName: '',
    period: [],
    valuesForCheckboxes: {
      entityClassArr:[],
      entityClassList:[],
      changeTypeArr:[],
      changeTypeList:[
        {name:'Изменение'},
        {name:'Создание'},
        {name:'Удаление'},

      ],
      usersArr:[],
      users:[],
      atributArr:[],
      atributs:[],
    },
    creationDateEnd: null,
    creationDateStart: null,
  }),
})
