
import { defineComponent, PropType } from "vue";
import { AreaApi } from "@/api/area.api";
import { SupportTeamApi } from "@/api/supportTeam.api";
import { IncidentsApi } from "@/api/incidents.api";
import { ServiceApi } from "../../api/service.api";
import {getIncidentData} from "@/consts/fields/incidents.fields";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import { defineTextColorByIncidentsStatus, defineTextColorByStatus } from "@/helpers/define-status-color";
import { AreasResponsibilityApi } from "@/api/areas-responsibility.api";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {IncidentTypeApi} from "@/api/incident-type.api";
import {currentUser} from "@/consts/currentUser";
import { UrgencyApi } from "@/api/urgency.api";
import {ClassifilesApi} from "@/api/classifires.api";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import {CounterpartyApi} from "@/api/counterparty.api";

interface Option {
  value: string;
}

export default defineComponent({
  name: "IncidentCharacteristics",
  mixins: [CharacteristicBase],
  data: function() {
    return {
    currentUser,
    areaList: [],
    supportTeamList: [],
    priorityList: [],
    additionalContact: [],
    selectedContacts: [],
    servicesList: [],
    statusList: [],
    sourceList: [],
    responseZoneList: [],
    typeList: [],
    urgencyList: [],
    equipmentList: [],
    objectListTotalCount: 0,
    responsebleListTotalCount: 0,
    responsebleListListCurrentSize: 10,
    objectListCurrentSize: 25,
    customerAgentList: [],
    userList: [],
    objectType: '',
    objectTypeId: [],
    objectTypeList: [],
    selectedClient: '',
    data: getIncidentData(),
    validationFields: [
      { field: "description", validators: ["required"] },
      { field: "priority.name", validators: ["required"] },
      // { field: "type.name", validators: ["required"] },
      { field: "monitoringObjectDto.name", validators: ["required"] },
    ],
    statusesXML: '',
    isBpmnOpened: false,
    activityInstance: null,
    viewer: null
  }},
  emits: ["select"],
  mounted() {
    this.getAreaList();
    this.getResponseZoneList();
    this.getSupportTeamList();
    this.getUrgencyList();
    this.getIncidentFilterList();
    this.getObjectTypeList();
    this.initMonitoringObject();
    this.getTypeList();
    this.getUserList();
    this.initServices();
    this.getCRUDSettings(57);
    if(!this.canEditResponseZone()) {
      this.getCustomerAgentList();
    }
    if( this.data.additionalContacts.length > 0) {
      let vr = [];
      this.data.additionalContacts.map(res => {
        vr.push(res.contactType.name + ': ' + res.contactInfo)
        this.selectedContacts.push(res.id)
      })
      this.data.additionalContacts = vr;
    }
    if (this.data.monitoringObjectDto?.client?.id) {
      this.selectedClient = this.data.monitoringObjectDto?.client?.id
    }
    this.getAdditionalContactList();
  },
  methods: {
    defineTextColorByStatus,
    defineTextColorByIncidentsStatus,
    toggleBPMN() {
      this.$emit('clickOpenBpmnButton')
    },
    getAreaList() {
      AreaApi.applyFilter({ name: this.data.area.name }, 10, 0).then((res) => {
        this.areaList = res.data;
      });
    },
    getResponseZoneList() {
      AreasResponsibilityApi.applyFilter({ name: this.data.responseZone.name }, 10, 0)
        .then(res => {
          this.responseZoneList = res.data;
          if(this.canEditResponseZone()) {
            let name: string;
            res.data.map((des) => {
              if(des.defaultName) {
                name = des.name
              }
              this.data.responseZone.name = name
            })
          }
        })
    },
    getAdditionalContactList() {
      if (this.selectedClient) {
        CounterpartyApi.getById(this.selectedClient)
            .then(res => {
              this.additionalContact = res.contacts;
            })
      }
    },
    loadMoreUser() {
      // Implement the logic to load more users or take necessary actions.
      console.log("Load more users");
      this.responsebleListListCurrentSize += 10;
      ExecutorUsersApi.applyFilter({userTypeId: [2],
        fio: this.data.responsible.name}, this.responsebleListListCurrentSize)
        .then((res) => {
          this.userList = res.data
          this.responsebleListTotalCount = res.totalCount
        })
    },
    getSupportTeamList() {
      SupportTeamApi.applyFilter(
        { name: '' },
        100,
        0
      ).then((res) => {
        this.supportTeamList = res.data;
      });
    },
      hasSupportEngineerRole() {
        return this.currentUser.value.userRoles.includes('support_engineer_internal')
      },
    getUrgencyList() {
      UrgencyApi.applyFilter({}).then((res) => {
        this.urgencyList = res.data;
      })
    },
    getIncidentFilterList() {
      IncidentsApi.getIncidentFilterList().then((res) => {
        this.priorityList = res.priority;
        this.statusList = res.statuses;
        this.sourceList = res.sources;
        if (!this.data.id) {
          let value;
        res.priority.map(des => {
            if (des.id === 1)
          {
            value =  des
          }
          }
          )
         this.data.priority = value
        }
      });
    },
    onSelectResponseble(value, option) {
      this.data.responsible.id = option.id;
      this.$emit("select", { option });
    },
    onSelect(value, option) {
      if(option.field === "service") {
        this.data[option.field] = option;
        this.$emit("select", { option });
        return;
      }
      if(option.field === "monitoringObjectDto") {
        const key = option.field === "service" ? "orderN" : "id";
        this.selectedClient = option.client.id
        this.data[option.field][key] = option[key];
        console.log(123123123)
        this.getCustomerAgentList();
        this.getAdditionalContactList();
        this.$emit("select", { option });
        return;
      }
      if(option.field === "customerAgent") {
        const key = option.field === "service" ? "orderN" : "id";
        this.selectedClient = option.client.id
        this.data[option.field][key] = option[key];
        this.initMonitoringObject();
        this.getAdditionalContactList();
        this.$emit("select", { option });
        return;
      }
      const key = option.field === "service" ? "orderN" : "id";
      this.data[option.field][key] = option[key];
      this.$emit("select", { option });
    },
    onSelectObject(value, option) {
        this.selectedClient = option.client.id
        this.data.monitoringObjectDto.id = option.id
        this.initMonitoringObject();
        this.getCustomerAgentList();
        this.getAdditionalContactList();
        this.$emit("select", { option });
        return;
    },
    cleanSelect(option) {
      if(option.field === "area") {
        this.data["service"] = { id: null, orderN: "", channelType: "" };
        this.data["monitoringObjectDto"] = { id: null, name: "", ipaddress: "" };
        this.initServices();
        this.initMonitoringObject();
      }
      if(option.field === "service") {
        this.data["monitoringObjectDto"] = { id: null, name: "", ipaddress: "" };
        this.initMonitoringObject();
      }
    },
    selectArea(value, option) {
      this.onSelect(value, option);
      this.cleanSelect(option);
    },
    cleanAutocomplete(option, event?) {
      if(!event) {
        this.cleanSelect(option);
      }
    },
    initServices() {
      if (this.data.area.id) {
        ServiceApi.applyFilter(
          { orderN: this.data.service.value, areaId: this.data.area.id },
          10,
          0
        ).then((res) => {
          this.servicesList = res.data;
        });
      } else {
        this.servicesList = [];
      }
    },
    selectService(value, option) {
      this.onSelect(value, option);
      this.cleanSelect(option);
    },
    getObjectTypeList() {
      MonitoringObjectsApi.getTypesList(this.objectType, 100)
          .then((res) => {
            this.objectTypeList = res.data;
          })
    },
    initMonitoringObject() {
        MonitoringObjectsApi.applyFilter(
          {  name: this.data.monitoringObjectDto.name,
            types: this.objectTypeId,
            isActive: true,
            clientIds: !!this.selectedClient ? [this.selectedClient] : []
          },

          this.objectListCurrentSize,
          0
        ).then((res) => {
          this.equipmentList = res.data;
          this.objectListTotalCount = res.totalCount;
        });
    },
    loadMoreObject() {
      this.objectListCurrentSize += 25;
      this.initMonitoringObject();
    },
    getTypeList() {
      IncidentTypeApi.applyFilter({}).then((res) => {
        this.typeList = res.data;
      })
    },
    clearAutocomplete(e) {
      if (e == undefined) {
        this.data.responseZone.name = '';
        this.getResponseZoneList();
      }
    },
    canEditResponseZone() {
     return  this.currentUser.value.userRoles.includes('client-operator')
    },
    objectTypeSelect(e,m) {
      this.objectTypeId = []
      this.objectTypeId.push(m.id)
      this.initMonitoringObject()
    },
    objectTypeDelete(e,m) {
      if (m === undefined) {
        this.objectTypeId = []
        this.initMonitoringObject();
      }
    },
    deleteValidation() {
      if(this.currentUser.value.userRoles.includes('client-operator')) {
        this.validationFields.splice(1,2)
      }
    },
    getCustomerAgentList () {
      ExecutorUsersApi.applyFilter({userTypeId: [1],
        fio: this.data.customerAgent.name, clientId: this.selectedClient}, 100)
          .then((res) => {
            this.customerAgentList = res.data
          })
    },
    getUserList () {
      ExecutorUsersApi.applyFilter({userTypeId: [2],
        fio: this.data.responsible.name}, 10)
          .then((res) => {
            this.userList = res.data
            this.responsebleListTotalCount = res.totalCount
          })
    },
    monitoringObjectSelect(e) {
     if (e === undefined) {
       this.data["monitoringObjectDto"] = { id: null, name: "", ipaddress: "" };
       this.data["customerAgent"] = { id: null, name: ""};
       this.selectedClient = '';
       this.getCustomerAgentList()
       this.initMonitoringObject()
     }
    },
    responsibleSelect(e) {
      if(e === undefined) {

        this.data.responsible.id = null
        this.data.responsible.name = ''
        console.log(this.data)
        this.getUserList();
      }
    },
    selectAdditionalContact(e,v) {
   console.log(e)
      console.log(v)
      this.selectedContacts.push(v.id)
    },
    deselectAdditionalContact(e,v) {
      // console.log('dis?', this.selectedContacts, 'splice', this.selectedContacts.indexOf(v.id), 1)
      // console.log(e)
      // console.log(v)
      let temp = [];
      for(let sc of this.selectedContacts) {
        if(sc !== v.id) {
          console.log(sc.id, ' !== ', v.id);
          temp.push(sc);
          console.log(sc);
        }
      }
      console.log('t1', temp);
      console.log('t2', this.selectedContacts);
      this.selectedContacts = temp;
      // this.selectedContacts.splice(this.selectedContacts.indexOf(v.id) - 1, 1)
    }
  },
  watch: {
    data: {
      handler() {
        if( this.data.additionalContacts.length > 0  && this.data.id && typeof this.data.additionalContacts[0] !== "string" ) {
          let vr = [];
          this.data.additionalContacts.map(res => {
            if(res.contactType) {
              vr.push(res.contactType.name + ': ' + res.contactInfo)
              this.selectedContacts.push(res.id)
            }
          })
          this.data.additionalContacts = vr;
        }
      },
      deep: true
    }
  }

});
