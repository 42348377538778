
import {mixins, Options, Vue} from "vue-class-component";
import FilterBase from "@/classes/FilterBase.vue";
import Super from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {ChannelTypesFilterImp} from "@/components/classes/ChannelTypesFilterImp";

export default defineComponent({
  name: "ChannelTypesFilter",
  components: {
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    applyFilter() {
      this.filterChange(new ChannelTypesFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ['id', 'name']);
      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['id', 'name']);
    },
    cleanFilter() {
      this.filter = new ChannelTypesFilterImp();
      this.setFilterFields();
      this.filterChange(new ChannelTypesFilterImp());
      this.$emit("filter", this.filter);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'name') {
              this.filter.name = '';
            }
          });
    },
  },
  data: () => ({
    filter: new ChannelTypesFilterImp(),
    valuesForCheckboxes: {
    },
    filterData: {}
  }),
  mounted() {
    this.getQueryParams();
    this.setFilterFields();
    this.applyFilter();
  },
  setup() {
    return {
      locale
    }
  },
})
