
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {},
        data() {
            return {
              info: '',
              innerHTMLInfo: '',
            }
        },
        setup() {
            const visible = ref <boolean> (false);

            const showModal = () => {
                visible.value = true;
            };

            const handleOk = (e: MouseEvent) => {
                visible.value = false;
            };

            const handleCancel = () => {
                visible.value = false;
            };

            return {
                visible,
                showModal,
                handleOk,
                handleCancel
            };
        },
        methods: {
            openDialog() {
              this.info = localStorage.getItem('info');
              this.showModal();
              this.innerHTMLInfo = this.info;
            }
        },
    });
