
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import draggable from "@/components/base/dnd/draggable.js";
import {DTO_FIELDS} from "@/consts/dto";
import {SettingsApi} from "@/api/settings.api";
import {getViewSettings} from "@/consts/settings";
import {suffix} from "@/consts/nameSuff";

export default defineComponent ({
  components: {
    draggable,
  },
  props: {
    fieldsSettings: [],
  },
  mixins: [CharacteristicBase],
  data: () => ({
    data: {fields: []},
    freeSelectorData: [],
    newObject: {
      id: null,
      displayList: false,
      dtoField: "",
      name: "",
      fieldOrder: 0
    },
    exceptsKeys: ['dtoField']
  }),
  watch: {
    clonedItems: {
      handler(newValue, oldValue) {
        const newFields = newValue.fields;
        const oldFields = oldValue.fields;
        console.log('newFields', newFields);
        console.log('oldFields', oldFields);
        if(newFields.length === oldFields.length){
          return;
        }
        if(oldFields && (newFields.length === oldFields.length)) {
          oldFields.forEach((oldF, ind) => {
            if (newFields[ind].dtoField !== oldF.dtoField) {
              this.freeSelectorData.push({value: this.getNameField(oldF.dtoField), key: oldF.dtoField });
            }
          });
        }
      },
      deep: true
    }
  },
  methods: {
    setSelectData(data: []) {
      this.freeSelectorData = data;
    },
    getDTOName() {
      const DTOName = DTO_FIELDS[this.$route.params.id];
      suffix.next(' ' + DTOName);
      return DTOName;
    },
    add() {
      console.log(this.freeSelectorData);
      this.newObject.displayList = false;
      this.newObject.dtoField = this.freeSelectorData[0].key;
      this.newObject.value = this.freeSelectorData[0].value;
      this.data.fields.push(JSON.parse(JSON.stringify(this.newObject)));
      const ind = this.freeSelectorData.map(d => d.key).indexOf(this.newObject.dtoField);
      this.freeSelectorData.splice(ind, 1);
    },
    removeField(field) {
      this.freeSelectorData.push({value: this.getNameField(field.dtoField), key: field.dtoField});
      this.data.fields.splice(this.data.fields.indexOf(field), 1);
    },
    getList() {
      let result = [];
      for(let i = 0; i < this.freeSelectorData.length; i++) {
        if (this.data.fields.filter(field => field.dtoField === this.freeSelectorData[i].key).length == 0) {
          result.push(this.freeSelectorData[i]);
        }
      }
      return result;
    },
    selectField(field, i){
      this.data.fields[i].dtoField = this.freeSelectorData.find(selectorDataField => selectorDataField.value === field.value).key
      console.log(field);
      console.log('field2', this.freeSelectorData);
      const ind = this.freeSelectorData.map(d => d.value).indexOf(field.value);
      this.freeSelectorData.splice(ind, 1);
    },
    getNameField(val) {
      let fields = JSON.parse(JSON.stringify(this.fieldsSettings));
      if(!fields) {
        return val;
      }
      const result = fields.find(field => field.field == val);
      if(result) {
        return result.name;
      }
      return val;
    }
  },
  computed:{
    clonedItems: function(){
      return JSON.parse(JSON.stringify(this.data));
    }
  },
  mounted() {
    this.getDTOName()
  }
})
