
    import {defineComponent, ref} from 'vue';
    import {IncidentsApi} from "@/api/incidents.api";
    import {currentUser} from "@/consts/currentUser";
    import {IncidentListData} from "@/types/incident";

    export default defineComponent({
        components: {},
        data() {
            return {
                title: 'Добавление комментария',
                incidentId: null,
                currentUser: null,
                commentForm: {
                    comment: '',
                    fileList: []
                },
            }
        },
        setup() {
            const visible = ref <boolean> (false);

            const showModal = () => {
                visible.value = true;
            };

            const handleOk = (e: MouseEvent) => {
                visible.value = false;
            };

            const handleCancel = () => {
                visible.value = false;
            };

            return {
                visible,
                showModal,
                handleOk,
                handleCancel
            };
        },
        methods: {
            openDialog(id, title?) {
              if(title) {
                this.title = title;
              }
                this.incidentId = id;
                this.showModal();
            },
            handleChange(files) {
                files.fileList = this.commentForm.fileList = files.fileList.map(_ => {_.status = 'done'; return _})
            },
            addComment() {
                IncidentsApi.addComment({
                    authorId: currentUser.value.id,
                    incidentId: this.incidentId,
                    text: this.commentForm.comment
                }).then((id) => {
                    this.uploadFiles(id);
                    this.$notification.open({
                      message: `Заявка с номером ${this.incidentId} обновлена`,
                      style: {
                        width: "500px",
                        marginLeft: `${400 - 500}px`,
                        fontSize: "14px",
                      },
                      placement: 'bottomRight',
                      duration: 3,
                    });
                });
            },
            uploadFiles(id) {
                const promises = [];
                this.commentForm.fileList.forEach(({name, originFileObj}) => {
                    const formData = new FormData();
                    formData.append('file', originFileObj, name);
                    formData.append('sourceId', id);
                    formData.append('source', 'COMMENT');
                    promises.push(IncidentsApi.uploadCommentFiles(formData));
                });
                Promise.all(promises).then(_ => {
                    this.handleCancel();
                })
            },
            customRequest(ev) {
                console.log(ev);
            },
        }
    });
