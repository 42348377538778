<template>
  <div class="flex cursor-pointer" @click.prevent="toggle">
    <slot name="label"></slot>
    <div>
      <img
        src="../../assets/icons/ui/arrow.svg"
        alt=""
        class="accordion-arrow"
        :class="{ expanded: expanded }"
      />
      <transition
        enter-active-class="enter-active"
        leave-active-class="leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div v-if="expanded" @click.stop="">
          <div class="pt-4">
            <slot></slot>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    duration: {
      type: Number,
      default: 250,
    },
    expand: false
  },
  data: () => ({
    expanded: false
  }),
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    /**
     * @param {HTMLElement} element
     */
    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.maxHeight = "0px";
        }

        element.style.display = null;
      });
    },
    /**
     * @param {HTMLElement} element
     */
    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.maxHeight = `500px`;
        });
      });
    },
    /**
     * @param {HTMLElement} element
     */
    afterEnter(element) {
      element.style.height = null;
    },
    /**
     * @param {HTMLElement} element
     */
    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    },
    /**
     * @param {HTMLElement} element
     */
    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.maxHeight = "0px";
        });
      });
    },
    /**
     * @param {HTMLElement} element
     */
    afterLeave(element) {
      element.style.maxHeight = null;
    },
  },
  mounted() {
    this.expanded = this.expand;
  }
})
export default class Accordion extends Vue {}
</script>

<style scoped lang="scss">
.enter-active,
.leave-active {
  overflow: hidden;
  transition: max-height .3s ease-in-out;
}

.accordion-arrow {
  transition: transform 0.3s ease-in-out;
  &.expanded {
    transform: rotate(180deg);
  }
}
</style>
