export class CounterpartyFilterImp {

    name = ""
    inn = ""
    kpp = ""
    ogrn = ""
    shortName = ""
    email = ""
    phone = ""
    startWork = ""
    endWork = ""
    contactUserIds = []
    statusIds = []
    typeIds = []
    sortParams = [{sortFieldName: "name", direction: "desc"}]
}