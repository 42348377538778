export class AuthlogFilterImp {
    userId = null
    ip = ''
    eventTimeFrom = ''
    eventTimeTo = ''
    eventTypes = [
        'LOGIN'
    ]
    sortParams = [{
    sortFieldName: "eventTime",
    direction: "desc"
}]
}