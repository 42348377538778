
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getIncidentData} from "@/consts/fields/incidents.fields";

export default defineComponent ({
  name: "IncidentServiceInformation",
  mixins: [CharacteristicBase],
  data: () => {
    return {
      data: getIncidentData(),
    }
  },
  mounted() {
    console.log(this.data, 'data')
  }
})
