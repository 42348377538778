
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getChannelTypesData} from "@/consts/fields/channel-types.fields";

export default defineComponent({
  name: "ChannelTypesCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getChannelTypesData(),
    validationFields:[
      {field: "name", validators: ["required"]},
    ],
  }),
  methods: {
  },
  mounted() {
    this.getCRUDSettings(105)
  }
})
