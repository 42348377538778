
    import {defineComponent, ref} from 'vue';
    import IncidentHistoryList from "@/components/incidents/IncidentHistoryList.vue";
    import {incidentHistoryFields} from "@/consts/fields/incidents.fields";
    import {IncidentsApi} from "@/api/incidents.api";

    export default defineComponent({
        components: {IncidentHistoryList},
        data() {
            return {
                history: {
                    search: '',
                    fields: incidentHistoryFields.slice(),
                    list: [],
                    size: 10,
                    page: 0,
                    totalCount: 0,
                    sortParams: [{sortFieldName: "createTime", direction: "desc"}]
                },
                incidentId: null,
              isLoad: false
            }
        },
        setup() {
            const visible = ref <boolean> (false);

            const showModal = () => {
                visible.value = true;
            };

            const handleOk = (e: MouseEvent) => {
                visible.value = false;
            };

            const handleCancel = () => {
                visible.value = false;
            };

            return {
                visible,
                showModal,
                handleOk,
                handleCancel
            };
        },
        watch: {
            'history.search'() {
                this.getHistoryList()
            },
        },
        methods: {
            openDialog(id) {
                this.incidentId = id;
                this.getHistoryList();
                this.showModal();
            },
            handleSearchHistoryChanges(ev) {
                console.log(ev);
            },
            getHistoryList() {
              this.isLoad = false;
                IncidentsApi.getHistoryList({
                    incidentId: this.incidentId,
                    containsValue: this.history.search,
                    sortParams: this.history.sortParams
                }, this.history.size, this.history.page).then(({data, pageCount, totalCount}) => {
                    this.history.list = data;
                    this.history.totalCount = totalCount;
                    this.isLoad = true;
                });
            },
            updateList(value, from: 'history' | 'comment') {
                const {get, params} = this.definedGetList(from);
                let column = value.columnKey;
                let order = '';
                if(value.order) {
                    order = value.order === 'descend' ? 'desc': 'asc';
                } else {
                    params.sortParams = [{sortFieldName: "createTime", direction: "desc"}];
                    get();
                    return;
                }
                let sortParams: any =
                    {sortFieldName: column, direction: order};
                params.sortParams = [sortParams];
                get();
            },
            updatePage({page, size}, from: 'history' | 'comment') {
                const {get, params} = this.definedGetList(from);
                params.size = size;
                params.page = page - 1;
                get();
            },
            definedGetList(type: 'history' | 'comment') {
                if(type == 'history') {
                    return {get: () => this.getHistoryList(), params: this.history}
                }
            },
        }
    });
