export const getContacts = () => ([
    {
        id: 1,
        contactInfo: '',
        contactType: {
            id: 2,
            name: "Email",
            description: "Дежурный Email"
        }
    },
    {
        id: 0,
        contactInfo: '',
        contactType: {
            id: 1,
            name: "Телефон",
            description: "Дежурный телефон"
        }
    }
]);
