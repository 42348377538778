export class IncidentFilterImp {
    id = "";
    areaId = "";
    areaNames = null; //
    orderN = "";
    creationDate = null;
    creationDateEnd = null;
    creationDateStart = null;
    responseZoneId = null;
    deleted = false;
    description = "";
    responseZone = null;
    incidentSources = [];
    incidentStatuses = [1, 6, 2, 3];
    massIncidentId = "";
    massIncidentNames = null;
    massIncidentTypes = [];
    metricId = "";
    metricNames = null;
    metrics = [];
    name = "";
    onWorkTime = false;
    priorities = [];
    services = [];
    sortParams = [{sortFieldName: "id", direction: "desc"}];
    stages = [];
    supportTeams = [];
    timeWorkingEnd = null;
    timeWorkingStart = null;
    typeId = "";
    typeNames = null;
    typePassedTime = null;
    types = [];
    clientId = [1];
    customAttributes = [];
    customerAgentIds = [];
    responsible: ''
}
