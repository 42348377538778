
import FilterBase from "@/classes/FilterBase.vue";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {ReportsFilterImp} from "@/components/classes/ReportsFilterImp";
import moment from "moment";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import {Util} from "@/components/utils/Util";
import Accordion from "@/components/ui/Accordion.vue";
import { ReportsApi } from "@/api/reports.api";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "ReportsFilter",
  components: {
      Accordion
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    getAuthors(authorName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.authorsArr.length > 0) {
        this.valuesForCheckboxes.authorsArr.forEach(id => {
          const checked = this.valuesForCheckboxes.authors.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = authorName || this.author;
      ExecutorUsersApi.getUserByName(name)
          .then(res => {
            console.log(res);
            this.valuesForCheckboxes.authors = Util.transformToCheckboxes2(res);
            if(arrChecked.length > 0) {
              arrChecked.forEach(c => {
                if (!this.valuesForCheckboxes.authors.some(mg => mg.id === c.id)) {
                  this.valuesForCheckboxes.authors.push(c);
                }
              });
            }
          });
    },
    changeFilter() {
      this.setQueryParams();
    },
    getReportTypes() {
      ReportsApi.getReportType()
        .then(res => {
          this.valuesForCheckboxes.reportTypes = this.reportTypes = Util.transformToCheckboxes(res);
        })
    },
    applyFilter() {
      if (this.startTime) {
        this.filter.startTime = moment(this.startTime).format("DD.MM.YYYY");
      } else {
        this.filter.startTime = null;
      }
      if (this.endTime) {
        this.filter.endTime = moment(this.endTime).format("DD.MM.YYYY");
      } else {
        this.filter.endTime = null;
      }
      this.filter.authorId = (this.valuesForCheckboxes.authorsArr.length === 1
          && this.valuesForCheckboxes.authorsArr[0] === '')
          ? null : this.valuesForCheckboxes.authorsArr[0];
      this.filter.typeIds = (this.valuesForCheckboxes.reportTypeArr.length === 1
          && this.valuesForCheckboxes.reportTypeArr[0] === '')
          ? [] : this.valuesForCheckboxes.reportTypeArr;
      if (this.currentUser.value.userRoles.includes('client-operator')) {
        this.filter.authorId = this.currentUser.value.id
      }
      this.filterChange(new ReportsFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      if (!this.$route.query.isRelated) {
        let queryParams: any = {};
        if(this.startTime) {
          queryParams.startTime = moment(this.startTime).format('DD.MM.YYYY');
        }
        if(this.endTime) {
          queryParams.endTime = moment(this.endTime).format('DD.MM.YYYY');
        }
        this.setQueryFromFilterParam(queryParams, []);
        this.$router.push({query: queryParams});
      }
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true;
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      if(query.startTime) {
        this.startTime = moment(query.startTime, 'DD.MM.YYYY');
      }
      this.setFilterFromQueryParam(query, []);
    },
    cleanFilter() {
      this.endTime = null;
      this.startTime = null;
      this.filter = new ReportsFilterImp();
      this.setFilterFields();
      this.filterChange(new ReportsFilterImp());
      this.$emit("filter", this.filter);
    },
    findReportTypes() {
      const arrChecked = [];
      if (this.valuesForCheckboxes.reportTypeArr.length > 0) {
        this.valuesForCheckboxes.reportTypeArr.forEach(id => {
          const checked = this.valuesForCheckboxes.reportTypes.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      this.valuesForCheckboxes.reportTypes = Util.transformToCheckboxes(this.reportTypes.filter(({label}) => label.includes(this.reportName)));
      if (arrChecked.length > 0) {
        arrChecked.forEach(c => {
          if (!this.valuesForCheckboxes.reportTypes.some(mg => mg.id === c.id)) {
            this.valuesForCheckboxes.reportTypes.push(c);
          }
        });
      }
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'startTime') {
              this.filter.startTime = '';
              this.startTime = null;
            }
            if(f.field === 'endTime') {
              this.filter.endTime = '';
              this.endTime = null;
            }
            if(f.field === 'author') {
                this.valuesForCheckboxes.authors = [''];
            }
            if(f.field === 'author') {
                this.valuesForCheckboxes.authors = [''];
            }
          });
    },
  },
  data: () => ({
    reportName: '',
    author: '',
    endTime: null,
    startTime: null,
    filter: new ReportsFilterImp(),
    valuesForCheckboxes: {
      authors: '',
      authorsArr: [],
      reportTypes: [],
      reportTypeArr: [''],
    },
    reportTypes: [],
    filterData: {},
    currentUser,

  }),
  mounted() {
      Promise.all([this.getAuthors(), this.getReportTypes()])
          .then(() => {
              this.getQueryParams();
              this.setFilterFields();
              this.applyFilter();
          });
  },
})
