
import { defineComponent, ref } from "vue";
import ListBase from "@/classes/ListBase.vue";
import IncidentHistoryDialog from "@/components/incidents/IncidentHistoryDialog.vue";
import IncidentCommentDialog from "@/components/incidents/IncidentCommentDialog.vue";
import {IncidentsApi} from "@/api/incidents.api";
import {currentUser} from "@/consts/currentUser";
import moment from "moment";

export default defineComponent({
  mixins: [ListBase],
  components: { IncidentHistoryDialog, IncidentCommentDialog },
  data: () => ({
    buttonWidth: ref<number>(30),
    currentUser,
    nextStatuses: null,
  }),
  mounted() {
    if(this.canEditResponseZone()) {
      this.filteredFields.splice(this.filteredFields.indexOf(this.filteredFields.find(res => res.field == 'deleted')), 1)
    }
  },
  methods: {
    test(e) {
      console.log(e)
    },
    canEditResponseZone() {
      return  this.currentUser.value.userRoles.includes('client-operator')
    },
    addComment(record) {
      this.$refs.comment.openDialog(record.id, 'Добавление комментария для заявки ' + record.incidentNumber);
    },
    showHistory(record) {
      this.$refs.history.openDialog(record.id);
    },
    getNextStatusesById(incidentId, isVisible) {
      if (!isVisible) {
        return;
      }
      this.nextStatuses = null;
      IncidentsApi.getNextStatusesById(incidentId)
        .then(res => {
          this.nextStatuses = res;
        })
    },
    setStatus(status, incidentId) {
      IncidentsApi.setStatus({
        status,
        comment: "string",
        createTime: moment().utc(),
        authorId: currentUser.value.id,
        incidentId
      }).then(res => {
        this.$notification.open({
          message: `Заявка с номером ${incidentId} обновлена`,
          style: {
            width: "500px",
            marginLeft: `${400 - 500}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.$emit("updateList")
      })
    }
  },
});
