
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getUnitData} from "@/consts/fields/units.fields";

export default defineComponent({
  name: "UnitCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getUnitData(),
    validationFields:[
      {field: "name", validators: ["required"]}
    ],
  }),
  methods: {
  }
})
