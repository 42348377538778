export class ServiceFilterImp {
    id = ""
    areaCode = ""
    areaName = ""
    channelType = ""
    client = ""
    orderN = ""
    connectSpeed = ""
    containsValue = ""
    contractNum = ""
    dateStartService = ""
    isActive = null
    serviceStatus = ""
    serviceStatuses = []
    sortParams = [{sortFieldName: "id", direction: "desc"}]
    measure = ""
    status = ""
    trafficSize = ""
    channelTypes = []
    areaIds = []
    contractIds = []
}
