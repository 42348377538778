
  import {defineComponent, PropType} from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {RolesApi} from "@/api/roles.api";

export default defineComponent({
  components: {DeleteDialog},
  mixins: [ListBase],
  props: {
    userId: {default: null},
    groupId: {default: null},
  },
  data: () => ({
    id: '',
  }),
  methods: {
    deleteRelatedRole() {
      if(this.userId) {
        RolesApi.deleteRoleUser(this.userId, this.id).then(() => {
          this.onChange(this.currentPage, this.currentSize);
        });
        return;
      }
      if(this.groupId) {
        RolesApi.deleteRoleGroup(this.groupId, this.id).then(() => {
          this.onChange(this.currentPage, this.currentSize);
        });
      }
    },
    openDeleteDialog(id, name) {
      this.id = id;
      this.$refs.delete.openDialog("связи", `связь с ролью ${name}`);
    }
  }
});
