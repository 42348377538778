export class MetricIndicatorFilterImp {
    id = ""
    active = ""
    activity = null
    codeName = ""
    key = ""
    measure = ""
    metricGroupsIds = []
    name = ""
    description = ""
    scale = ""
    sortParams = [{sortFieldName: "id", direction: "desc"}]
}
