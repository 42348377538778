
  import { defineComponent } from "vue";
  import ListBase from "@/classes/ListBase.vue";
  import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
  import {RolesApi} from "@/api/roles.api";

  export default defineComponent({
    components: {DeleteDialog},
    mixins: [ListBase],
    props: {
      roleId: {default: null},
    },
    data: () => ({
      id: ''
    }),
    methods: {
      deleteRelatedGroup() {

        RolesApi.deleteRoleObjectRelation(this.$route.params.id, this.id).then(() => {
          this.onChange(this.currentPage, this.currentSize);
        });
      },
      openDeleteDialog(id, name) {
        this.id = id;
        this.$refs.delete.openDialog("связи", `связь с объектом роли ${name}`);
      }
    }
  });
