export class MetricFilterImp {
    id = ""
    active = ""
    activity = ""
    key = ""
    limitValue = ""
    displayUnit = ""
    measure = ""
    metricGroup = ""
    description = ""
    metricGroupsIds = []
    monitoringSystemName = ""
    name = ""
    testKpd = ""
    sortParams = [{sortFieldName: "id", direction: "desc"}]
}
