export class ExecutorUsersFilterImp {
    // executorIds = [];
    // fio = "";
    // groups = [];
    // isEnabled = null;
    // firstName = "";
    // middleName = "";
    // lastName = "";
    // login = "";
    // organization = "";
    // sortParams = [{sortFieldName: "id", direction: "asc"}];
    // supportTeam = "";
    // exceptSupportTeamId = "";
    // supportTeamIds = [];
    // userTypeId= []; //TODO: 1,2
    // clientId = "";
    // executorId = "";
    // exceptClientId = "";
    // exceptExecutorId = "";
    // clientIds = [];
    // positionIds = [];
    // positions = [];
    // roles = [];
    // keycloakUserIds = [];

    id = "";
    fio = "";
    login = "";
    isEnabled = null;
    userTypeId =[];
    lastname = "";
    organization = "";
    groups = [];
    executorIds = [];
    sortParams = [ {
        sortFieldName: "id",
        direction: "desc"
    }]
}

//     "exceptClientId": 0,
//     "exceptExecutorId": 0,
//     "keycloakUserIds": ["string"],
