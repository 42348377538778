export class EquipmentFilterImp {
    area = "";
    brands = [];
    brand = "";
    channelType = "";
    orderN = "";
    contractEnd = "";
    contractNum = ""
    createDate = ""
    dateStartService = ""
    equipmentStatuses = [];
    equipmentStatus = "";
    types = [];
    hostName = "";
    inventoryNum = "";
    ipAddress = "";
    models = [];
    model = null;
    name = "";
    number = "";
    serialNum = "";
    status = "";
    supportTeams = [];
    trafficSize = "";
    equipmentType = "";
    ipaddress= "";
    type = "";
    sortParams = [{ sortFieldName: "id", direction: "desc" }]
}
