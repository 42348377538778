
import { Options, Vue } from "vue-class-component";
// import {keycloak} from "@/main";
import ruRU from "ant-design-vue/es/locale/ru_RU";
import {Subject, takeUntil} from "rxjs";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import ChangePassDialog from "@/components/dialogs/ChangePassDialog.vue";

@Options({
  components: {ChangePassDialog,},
  props: {
    title: String,
    hasBackButton: Boolean,
  },
  data: () => ({
    locale: ruRU,
    suff: null,
    buttonWidth: 70,
    text: 'Are you sure to delete this task?',

    fio: '',
    firstName: '',
    lastName: '',
    middleName: '',
    keycloakId: '',
    subject$: new Subject(),
  }),
  mounted() {
    this.getName();
    setTimeout(() => {
      console.log('testovie testi');
      console.log(this.$attrs);
    }, 300);
  },
  methods: {
    getName() {
      suffix.pipe(takeUntil(this.subject$))
          .subscribe(value => this.suff = value);
      currentUser.subscribe(res => {
        this.fio = res.fio
        this.firstName = res.firstName
        this.lastName = res.lastName
        this.middleName = res.middleName
        this.keycloakId = res.keycloakId
      })
    },
    back() {
      // const paths = this.$route.path.split("/");
      // this.$router.push("/"+paths.slice(1, -1).join("/"))
      this.$router.go(-1);
    },
    exit() {
      localStorage.clear();

      // keycloak.logout().then(() => {
      //   console.log('Exit');
      // });
      currentUser.next({});
      console.log('EXIT');
      this.$router.replace("/login").then(_ => {
        location.reload();
      });
    },
    openChangePassDialog(e) {
      this.$refs.changepass.openDialog1("пользователя", `пользователя ${this.fio}`);
    },
    changePassword(e) {
      let pass = btoa(e)
      ExecutorUsersApi.changePassword(this.keycloakId,pass, {}).then(() => {
        this.$notification.open({
          message: "Пароль успешно изменен",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
      })
    },
  },
  beforeUnmount() {
    this.subject$.next();
    this.subject$.complete();
  }
})
export default class UpperMenu extends Vue {
  title!: string;
}
