
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getRolesData} from "@/consts/fields/roles.fields";
import {RolesApi} from "@/api/roles.api";

export default defineComponent({
  name: "RoleCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getRolesData(),
    menuItemsList: [],
    validationFields: [
      {field: "roleKeycloakName", validators: ["required"]},
    ],
    editCardCRUD: false,
    editCardCRUDTitle: '',
    editCardCRUDData: null
  }),
  methods: {
    selectParentCheckbox(item, param) {
      if (item.children && item.children.length > 0) {
        for ( let child of item.children) {
          child[param] = item[param]
        }
      } else {
        for (let obj of this.menuItemsList) {
          if (obj.children && obj.children.length > 0) {
            if(obj.children.find(res => res === item)) {
             this.menuItemsList[this.menuItemsList.indexOf(obj)][param] = true
            }
          }
        }
      }
    },
    editCardCRUDOpen(item) {
      console.log(item)
      this.editCardCRUDTitle = item.menuName
      this.editCardCRUD = true
      this.editCardCRUDData = item
    },
    saveEditCardCRUD() {
      let item  = this.editCardCRUDData
      item.roleKeycloakName = this.data.roleKeycloakName
      item.menuId = this.editCardCRUDData.id
      delete item.children
      delete item.id
      delete item.key
      delete item.menuCode
      console.log(item)
      RolesApi.saveFieldsCRUD(item)
          .then(() => {
            this.editCardCRUD = false
          })

    },
    closeEditCardCRUD() {
      this.editCardCRUD = false
    },
    clean() {
      this.data = getRolesData();
    },

    formatHierarchy(data) {
      data.children = data?.children?.length ? data.children.map(_  => {
        return this.formatHierarchy(_);
      }) : null;
      return {...data, title: data.name, key: data.id, children: data.children};
    },
    getMenuItems() {
      RolesApi.getMenuItems().then((res) => {
        for ( let item of res) {
          item.create = true
          item.read = true
          item.update = true
          item.delete = true
          if(item.children.length > 0) {
            for (let childItem of item.children) {
              childItem.create = true
              childItem.read = true
              childItem.update = true
              childItem.delete = true
            }
          }
        }
       this.menuItemsList = res.map(des => this.formatHierarchy(des))
      })
    },
    getRoleInfo() {
      RolesApi.getRoleInfoCRUD(this.data.roleKeycloakName)
          .then((res) => {
            this.menuItemsList = res.menuCrud.items.map(des => this.formatHierarchy(des))
          })
    }
  },
  mounted() {
    this.getCRUDSettings(95)
    if(!this.data.id) {
      this.getMenuItems()
    } else {
      this.data.roleKeycloakName = this.body.name
      this.getRoleInfo()
    }
  }
})
