
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getIncidentTypeData} from "@/consts/fields/incident-type.fields";

export default defineComponent({
  name: "IncidentTypeCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getIncidentTypeData(),
    validationFields:[
      {field: "name", validators: ["required"]}
    ],
  }),
  methods: {
  },
  mounted() {
    this.getCRUDSettings(103)
  }
})
