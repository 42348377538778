
import FilterBase from '@/classes/FilterBase.vue'
import locale from 'ant-design-vue/es/time-picker/locale/ru_RU'
import { Field } from '@/types/fields'
import { defineComponent, PropType } from 'vue'
import { UrgencyFilterImp } from '@/components/classes/UrgencyFilterImp'

export default defineComponent({
  name: 'UrgencyFilter',
  components: {},
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields()
        this.applyFilter('filter')
      },
      deep: true,
    },
  },
  methods: {
    changeFilter() {
      this.setQueryParams()
    },
    applyFilter() {
      this.filterChange(new UrgencyFilterImp())
      this.$emit('filter', this.filter)
    },
    setQueryParams() {
      if (!this.$route.query.isRelated) {
        let queryParams: any = {}
        this.setQueryFromFilterParam(queryParams, ['id', 'name'])
        this.$router.push({ query: queryParams })
      }
    },
    getFieldsVisible(name) {
      let result = this.fields.find((obj) => {
        return obj.field === name && obj.value === true
      })
      return result
    },
    getQueryParams() {
      const query = this.$route.query
      this.setFilterFromQueryParam(query, ['id', 'name'])
    },
    cleanFilter() {
      this.filter = new UrgencyFilterImp()
      this.setFilterFields()
      this.filterChange(new UrgencyFilterImp())
      this.$emit('filter', this.filter)
    },
    setFilterFields() {
      this.fields
        .filter((v) => v.value === false)
        .forEach((f) => {
          if (f.field === 'id') {
            this.filter.id = ''
          }
          if (f.field === 'name') {
            this.filter.name = ''
          }
        })
    },
  },
  data: () => ({
    filter: new UrgencyFilterImp(),
    valuesForCheckboxes: {},
    filterData: {},
  }),
  mounted() {
    this.getQueryParams()
    this.setFilterFields()
    this.applyFilter()
  },
  setup() {
    return {
      locale,
    }
  },
})
