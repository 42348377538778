export class RolesFilterImp {
    id = ""
    name = ""
    description = ""
    fields: []
    userId: null
    exceptUserId: null
    groupId: ""
    exceptGroupId: ""
    sortParams = [{ sortFieldName: "id", direction: "desc" }]
}
