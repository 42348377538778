
import { defineComponent } from 'vue'
import CharacteristicBase from '@/classes/CharacteristicBase.vue'
import { getUrgencyData } from '@/consts/fields/urgency.fields'

export default defineComponent({
  name: 'UrgencyCharacteristics',
  mixins: [CharacteristicBase],
  data: () => ({
    data: getUrgencyData(),
    validationFields: [{ field: 'name', validators: ['required'] }],
  }),
  methods: {},
  mounted() {
    this.getCRUDSettings(102)
  }
})
