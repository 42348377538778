export class MonitoringObjectsFilterImp {
    id = '';
    fields = [];
    name = "";
    areaIds = [];
    isActive = null;
    ipAddress = "";
    systemNum = "";
    serialNum = "";
    serviceId = null;
    exceptServiceId = null;
    areaName = "";
    exceptId = null;
    parentId = null;
    statuses = [];
    types = [];
    clientIds = [];
    brands = [];
    models = [];
    hierarchy = false;
    metricId = null;
    customAttributes = [];
    sortParams = [{ sortFieldName: "id", direction: "desc" }];
}
