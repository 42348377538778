
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { ReportsApi } from "@/api/reports.api";
import * as FileSaver from 'file-saver';

export default defineComponent({
  components: { DeleteDialog },
  mixins: [ListBase],
  data: () => ({
    id: '',
  }),
  methods: {
    deleteRelatedReport() {
      ReportsApi.delete(this.id).then(() => {
        this.onChange(this.currentPage, this.currentSize);
      });
    },
    openDeleteDialog(id, name) {
      this.id = id;
      this.$refs.delete.openDialog("отчета", `отчет ${name}`);
    },
    downloadReport(id) {
      ReportsApi.downloadReport(id).then(data => {
        const disposition = data.headers['content-disposition'].split('filename=')[1];
        FileSaver.saveAs(data.data, disposition);
      });
    }
  }
});
