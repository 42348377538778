
import {defineComponent} from "vue";
import ListBase from "@/classes/ListBase.vue";
import {formatDate} from "@/filters/date";


export default defineComponent ({
  mixins: [ListBase],
  methods: {
    test(e,r) {
      console.log(e)
      console.log(r)
    },
    test2(e) {
      console.log(e)
    },
    formateDate(e) {
      return formatDate(e, "DD.MM.yyyy, HH:mm")
    }
  },
  data: () => {
    return {
    expandList: {},

    }
  },
  mounted() {
    console.log(this.filteredFields)
    console.log(this.list)
  }
})
