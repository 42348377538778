
import {Options, Vue} from "vue-class-component";
import {RouteLocationNormalizedLoaded} from "vue-router";
import {currentUser} from "@/consts/currentUser";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {DTO_FIELDS} from "@/consts/dto";

interface MenuCell {
  svgName?: string;
  title: string;
  routeName?: string;
  children?: MenuCell[];
  role?: string;
  url?: string;
  menuCode: string;
}

@Options({
  data() {
    return {
      subject$: new Subject(),
      isLoad: false,
      activeKeys: ['99'],
      search: "",
      searchResult: [],
      bigLogo: '',
      smallLogo: '',
      bigLogoExtension: '',
      smallLogoExtension: '',
      menuCells: [
        {
          svgName: "monitoring.svg",
          title: "Мониторинг",
          routeName: "/",
          role: 'nav_monitoring',
          menuCode: 'monitoring',
          children: [
            {
              title: "Услуги",
              routeName: "services/",
              role: 'services_viewonly',
              withoutAccess: ['nav_contracts'],
              menuCode: 'service'
            },
            // {
            //   title: "Местоположения",
            //   routeName: "monitoring/areas/",
            // },
            // {
            //   title: "Объекты",
            //   routeName: "equipments/",
            // },
            {
              title: "Объекты",
              routeName: "monitoring/objects/",
              menuCode: 'object'
            },
          ],
        },
        {
          svgName: "incidents.svg",
          title: "Заявки",
          routeName: "/incidents/",
          menuCode: 'incidents',
          role: 'nav_incidents'
        },
        {
          svgName: "report.svg",
          title: "Отчеты",
          routeName: "/reports/",
          menuCode: 'reports',
          role: 'nav_reports'
        },
        {
          svgName: "analytics.svg",
          title: "Аналитика",
          routeName: "/dashboards/",
          menuCode: 'analytic',
          role: 'nav_analitics'
        },
        {
          svgName: "directory.svg",
          title: "Справочники",
          routeName: "/directory",
          role: ['nav_dictionary', 'support_engineer_internal'],
          url: "/directory/menu",
          menuCode: 'dictionary',
          children: [
            {
              title: "Группы метрик",
              routeName: "/metrics-groups",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'groups_of_metrics'
            },
            {
              title: "Договоры",
              routeName: "/contracts",
              menuCode: 'contracts'
            },
            {
              title: "Единицы измерения",
              routeName: "/measure",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'units_of_measurement'
            },
            {
              title: "Зоны ответственности",
              routeName: "/responsibility",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'areas_of_responsibility'
            },
            {
              title: "Категории метрик",
              routeName: "/metric-tag",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'categories_of_metrics'

            },
            {
              title: "Команды поддержки",
              routeName: "/support-teams",
              withoutAccess: ['nav_contracts'],
              menuCode: 'support_teams'
            },
            {
              title: "Контрагенты",
              routeName: "/counterparty",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'contractors'
            },
            {
              title: "Лимит метрик",
              routeName: "/metric-limits",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'metric_limit'
            },
            {
              title: "Местоположения",
              routeName: "/areas",
              withoutAccess: ['nav_contracts'],
              menuCode: 'locations'

            },
            {
              title: "Модели",
              routeName: "/models",
              withoutAccess: ['nav_contracts'],
              menuCode: 'model'
            },
            {
              title: "Правила расчета статусов",
              routeName: "/calculated-status",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'calculated_status_rule'
            },
            {
              title: "Производители",
              routeName: "/brands",
              withoutAccess: ['nav_contracts'],
              menuCode: 'manufacturers'
            },
            {
              title: "Подразделения",
              routeName: "/departments",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'divisions'
            },
            {
              title: "Служебные атрибуты",
              routeName: "/custom-attributes",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'service_attributes'
            },
            {
              title: "Статусы объектов мониторинга",
              routeName: "/monitoring-object-statuses",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'monitoring_object_statuses'
            },
            {
              title: "Срочность",
              routeName: "/urgency",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'urgency'
            },
            {
              title: "Типы заявок",
              routeName: "/incident-type",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'types_of_applications'
            },
            {
              title: "Типы местоположений",
              routeName: "/area-types",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'types_of_locations'
            },
            {
              title: "Типы метрик",
              routeName: "/metrics-type",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'types_of_metrics'
            },
            {
              title: "Типы объектов",
              routeName: "/equipment-types",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'types_of_objects'
            },
            {
              title: "Типы услуги",
              routeName: "/channel-types",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'types_of_services'
            },
            {
              title: "Шаблоны моделей здоровья",
              routeName: "/health-model-rule",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'template_for_health_models'
            },
            {
              title: "Шаблоны уведомлений",
              routeName: "/notice-template",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'notification_template'
            },
            {
              title: "Этапы внедрения",
              routeName: "/operation-queue",
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'stages_of_implementation'
            },
          ],
        },
        {
          svgName: "settings.svg",
          title: "Настройки",
          routeName: "/settings",
          role: 'nav_settings',
          menuCode: 'settings',
          children: [
            {
              title: "Процессы",
              routeName: "/processes",
              menuCode:'processes'
            },
            {
              title: "Управление файлами",
              routeName: "/file-manager",
              menuCode: 'file_management'
            },
            {
              title: "Правила действий",
              routeName: "/decision-definitions",
              menuCode: 'rules_of_action'
            },
            {
              title: "Конструктор процессов",
              routeName: "/process-templates",
              menuCode: 'process_designer'
            },
            {
              title: "Импорт данных",
              routeName: "/imports",
              menuCode: 'data_import'
            },
            {
              title: "Шаблоны мониторинга",
              routeName: "/monitoring-templates",
              menuCode: 'monitoring_templates'
            },
            {
              title: "Система",
              routeName: "/system",
              menuCode: 'system'
            },
            {
              title: "Почта",
              routeName: "/mail",
              menuCode: 'mail'
            },
            {
              title: "Оповещения",
              routeName: "/notification",
              menuCode: 'alerts'
            },
            {
              title: "Представления",
              routeName: "/representations",
              menuCode: 'performances'
            },
            {
              title: "Агенты сбора данных",
              routeName: "/collection-agents",
              menuCode: 'data_collection_agents'
            },
          ],
        },
        {
          svgName: "security.svg",
          title: "Безопасность",
          routeName: "/security",
          menuCode: 'security',
          role: 'nav_security',
          children: [
            {
              title: "Пользователи",
              routeName: "/executor-users",
              menuCode: 'users'
            },
            {
              title: "Группы",
              routeName: "/groups",
              menuCode: 'groups'
            },
            {
              title: "Роли",
              routeName: "/roles",
              menuCode: 'roles'
            },
            {
              title: "Журнал",
              routeName: "/changelog",
              menuCode: 'journal'
            },
            {
              title: "Журнал авторизации",
              routeName: "/authlog",
              menuCode: 'authorization_log'
            },
            {
              title: "Журнал сессий",
              routeName: "/active-sessions-log",
              menuCode: 'session_log'
            },
          ]
        },
        {
          svgName: "info.svg",
          title: "О системе",
          routeName: "/info/",
          menuCode: 'about_system',
          role: 'nav_settings'
        },
      ] as unknown as MenuCell[],
      currentMenuCell: null as MenuCell | null,
      openedCollapse: []
    };
  },
  props: {
    isOpened: {
      default: true
    }
  },
  mounted() {
    this.isLoad = false;
    this.bigLogo = localStorage.getItem('bigLogo')
    this.smallLogo = localStorage.getItem('smallLogo')
    this.smallLogoExtension = localStorage.getItem('smallLogoExtension')
    this.bigLogoExtension = localStorage.getItem('bigLogoExtension')
    this.getCurrentUser();
  },
  watch: {
    $route(to) {
      this.currentMenuCell = this.findCurrentMenuCell(to);
      this.currentCell();
    },
    search: {
      handler() {
        this.searchMenu();
      }
    }
  },
  methods: {
    getCurrentUser() {
      currentUser.pipe(takeUntil(this.subject$))
          .subscribe(value => {
            if (value != null) {
              this.isLoad = true;
              this.currentMenuCell = this.findCurrentMenuCell(this.$route);
            }
            this.currentCell();
          });
    },
    handleOpenButtonClick() {
      this.$emit('update:isOpened', !this.isOpened)
    },
    findCurrentMenuCell({
                          name,
                        }: {
      name: RouteLocationNormalizedLoaded["name"];
    }): MenuCell {
      return this.menuCells.find(({routeName}) => routeName === name);
    },
    isCurrentMenuCell(svgName: MenuCell["svgName"]) {
      return !!this.currentMenuCell && this.currentMenuCell.svgName === svgName;
    },
    isCheckMenuCell(routeName: string) {
      if (routeName === '/') return false;
      const count = (routeName.match(/\//g) || []);
      const currentRoute = this.$router.currentRoute.value.path;
      return !!this.$router.currentRoute
          && (routeName === currentRoute || (count.length > 1 &&
              ((currentRoute).includes(routeName) || (currentRoute + '/').includes(routeName))));
    },
    getImgUrl({svgName}: { svgName: MenuCell["svgName"] }) {
      return require(`/src/assets/icons/menu/${
          this.isCurrentMenuCell(svgName) ? "selected" : "unselected"
      }/${svgName}`);
    },
    searchMenu() {
      let result = [];
      if (this.search) {
        this.menuCells.forEach(menu => {
          // if (menu.title.toLowerCase().includes(this.search.toLowerCase())) {
          //   result.push({title: menu.title, routeName: menu.routeName});
          // }
          if (menu.children) {
            const route = menu.routeName;
            menu.children.forEach(mc => {
              if (mc.title.toLowerCase().includes(this.search.toLowerCase())) {
                result.push({title: mc.title, routeName: route + mc.routeName});
              }
            });
          }
        });
      }
      this.searchResult = result;
    },
    currentCell() {
      if (!!this.$router.currentRoute && this.$router.currentRoute.value.path) {
        let path = this.$router.currentRoute.value.path;
        this.menuCells.forEach((menu, ind) => {
          if (menu.children) {
            const route = menu.routeName;
            menu.children.forEach(mc => {
              const t = path + '/';
              if (path.includes((route + mc.routeName))
                  || t.includes((route + mc.routeName))) {
                // let activeKeys = [];
                // this.activeKeys.forEach(ak => activeKeys.push(ak));
                // if (!activeKeys.includes(ind + ''))
                //   activeKeys.push(ind + '');
                // this.activeKeys = activeKeys;
                this.activeKeys = [ind + ''];
              }
            });
          }
        });
      }
    },
    selectMenu(value, option) {
      this.$router.push(option.routeName);
    },
    routeToMenu(url) {
      this.$router.push(url);
    },
    hasAccessToRead(cell, child) {
      console.log(cell)
      if( child) {
        let result = false
        for(let item of  currentUser.value.access.items) {
           for(let childItem of item.children) {
             if( childItem.menuCode === cell.menuCode) {
               console.log(1322222222222222222222)
               result = childItem.read
             }
           }
        }
        console.log(result)
        return result
      }
      else {
        return  currentUser.value.access.items.find((res) => res.menuCode === cell.menuCode).read
      }

    },
  },
  beforeUnmount() {
    this.subject$.next();
    this.subject$.complete();
  },
})
export default class SidebarMenu extends Vue {
}
