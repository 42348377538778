export class CalculatedStatusRuleImpl {
    id: null;
    metricInstance: null;
    counterparty: null;
    minValue: null;
    minValueOperator: "";
    maxValue: null;
    maxValueOperator: "";
    status: null;
    colorCode: "";
    sortParams = [{sortFieldName: "id", direction: "desc"}]
}
